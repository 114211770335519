<template>
  <!-- <div class="go-topper" @click="goTop" v-if="topperShow"></div> -->
  <el-backtop target="#app" :right="188" :bottom="188"></el-backtop>
</template>
<script>
import Vue from 'vue'
import { backtop, icon } from 'element-ui'
Vue.use(backtop).use(icon)
export default {
  name: 'to-topper-component',
  data() {
    return {
      topperShow: false, // 快速回到头部的 显示
    }
  },
  computed: {},
  methods: {
    goTop() {
      this.goToTop()
    },
    handleScroll() {
      let dom = document.querySelector('#app')
      this.scrollTop = dom.scrollTop
      this.topperShow = this.scrollTop > 300
    },
    goToTop() {
      //回到顶部方法 点击按钮调用

      let top = document.querySelector('#app').scrollTop

      // 实现滚动效果

      const timeTop = setInterval(() => {
        document.querySelector('#app').scrollTop = top -= 80

        if (top <= 0) {
          clearInterval(timeTop) //清除定时器
        }
      }, 10)
    },
  },
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
    this.$nextTick((_) => {
      window.addEventListener('scroll', this.handleScroll, true)
    })
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
.el-backtop {
  z-index: 199;
  right: 188px;
  width: 50px;
  height: 50px;
  color: #3659f1;
  font-size: 24px;
}
.go-topper {
  position: fixed;
  z-index: 199;
  right: 188px;
  bottom: 150px;
  width: 50px;
  height: 50px;
  opacity: 0.5;
  border: 1px solid #ced1d6;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 0 7px 0 #e0e0e0;
  cursor: pointer;
  transition: all 1.5s;
  &:hover {
    opacity: 1;
  }
}

</style>
